import React from 'react'
import { withTranslation } from 'react-i18next';
import {Footer, SignupDivider, 
//HeroPlain, Location
} from '../../components';
import {WebLayout} from '../../layouts';

const AboutPage = ({t}) => (
  <WebLayout alwaysSticky={true}
  		link={[{
          rel: "alternate", href: "https://www.duuers.com/fi/about", hreflang: "fi"
      },{
          rel: 'alternate', href: 'https://www.duuers.com/en/about', hreflang: 'en'
      },{
          rel: 'alternate', href: 'https://www.duuers.com/en/about', hreflang: 'x-default'
      }]}
      title="Duuers yrityksenä - keitä me olemme?"
      meta={[
        {name: 'description', content: 'Me Duuersilla haluamme tehdä asiat fiksummin ja auttaa siinä myös asiakkaitamme. Lue lisää yrityksestämme tai tutustu avoimiin työpaikkoihin!'},
        {name: 'keywords', content: ''},
      ]}
    >
    {
   /* <HeroPlain background="/images/hero_team.jpg">
    </HeroPlain>*/}
    <section className="content_4 bg_white padding_bottom25">
      <div className="container nopadding padding_left20 width_full text-left">
     {/*    <h2 className="font_second light font42 top140 padding_bottom20 ">Duuers.com on nyt osa Louhi Networks Oy:tä</h2> */}
        <h2 className="font_second light font42 top140 padding_bottom20 ">{t('component.aboutus.maintitle')}</h2>
      	
    {/*    <div className="width_full margin_auto top35 font20 light text">
          Louhi Networks on hankkinut Duuers.com tarjoustyökalun oikeudet itselleen ja kehittää palvelua yhdessä asiakkaidensa kanssa eteenpäin.
          <br></br>
          <br></br><div className="col-lg-7 float_right">
      		<img srcSet="" src="/images/hero_hands.jpg" className="padding_top20 padding_bottom20 width_full" alt="" />	
        </div>
          Louhi Networks tukee asiakkaidensa menestystä tuottamalla ratkaisuja, jotka mahdollistavat digitaalisen identiteetin, myynnin ja yhteistyön verkossa. Louhi palvelee yli 20.000 yritystä yhden hengen yrityksistä suuriin pörssiyhtiöihin suomeksi ja englanniksi.
          <br></br><br></br>
          Liikevaihtomme vuonna 2019 oli 2,3 mEur. Louhessa asiakkaita auttaa 11 asiantuntijaa. Lisätietoja Louhen tarinasta löydät <a href="https://www.louhi.fi/yritys/louhen-historia/" target="_blank" rel="noopener noreferrer" className="link blue"> täältä </a> ja palveluistamme kotisivuilta <a href="https://www.louhi.fi/" target="_blank" rel="noopener noreferrer" className="link blue"> www.louhi.fi</a>. 
          <br></br>
          <br></br>
        </div> */}
        
        
        
      {/*     <div className="width_full margin_auto top35 font20 light text">
             Ennen tarjoukset kirjoitettiin paperille ja laitettiin postiin. Sitten siirryttiin käyttämään tekstinkäsittelyohjelmia. Tarjoukset laitettiin kuitenin edelleen postiin - vaikkakin sähköpostiin. Toimintatapa on säilynut samana vaikka kynä vaihtui ensin kirjoituskoneeseen ja sitten tietokoneeseen.
          </div> */}
          <div className="width_full margin_auto top35 font20 light text">
             {t('component.aboutus.text1')}
         </div>
          <div className="col-lg-7 float_right">
      		<img srcSet="" src="/images/hero_hands.jpg" className="padding_top20 padding_bottom20 width_full" alt="" />	
          </div>
        
         <div className="width_full margin_auto top35 font20 light text">
             {t('component.aboutus.text2')}
         </div>
         <div className="width_full margin_auto top35 font20 light text">
             {t('component.aboutus.text3')}
         </div>
         <div className="width_full margin_auto top35 bottom30 font20 light text">
             {t('component.aboutus.text4')}
         </div>
        
      {/*  <div className="width_full margin_auto top35 font20 light text">
             Duuers syntyi ajatuksesta, että tarjoukset voisi luoda aidosti digitalisuutta hyödyntävällä tavalla. Se tarkoittaa tarjousten ja tarjouksen osien digitaalista kirjastoa, josta tarjouksien luominen on helppoa kuin Lego-palikoiden käyttö - intuitiivista ja nopeaa. Duuersissa tarjous ei ole sidottu paperinamakuiseen esitystapaan vaan niistä on helppo tehdä ulkonäöllisesti miellyttäviä, selkeitä ja siten myyvempiä. Nopeasti tehdyt ammattimaisen näköiset tarjoukset kielivät palvelujen laadusta ja johtavat paremmin kauppaan. 
          </div>
          <div className="width_full margin_auto top35 font20 light text">
             Asiakas ei joudu taistelemaan liitetiedostojen ja versioiden kanssa vaan hän saa  linkin tarjoukseen verkossa, jossa siitä voi keskustella suljetulla asiakkaan ja myyjän välisellä keskustelupalstalla tarjouskohtaisesti.  Kun yhteinen sävel löytyy, voi asiakas hyväksytä tarjouksen sähköisesti ja tallentaa oman kopionsa siitä omalle koneelleen. Digitaalista ja kaikille osapuolille läpinäkyvää!
          </div>
          <div className="width_full margin_auto top35 font20 light text">
             Louhi Networksin missiona on tarjota asiakkailleen yrityksen digitaalisen identiteetin ja työn ratkaisuja, joilla liiketoiminnan tekeminen verkossa tehostuu ja helpottuu. Siksi Duuerskin on osa palvelutarjotaamme. Autamme kaikkia yrityksiä freenlancereistä isioihin yritksiin näkymään omalla tunnuksellaan verkossa ja tekemään yhteistyötä asikkaidensa kanssa digitaalisesti ja kehittämään liiketoimintaansa.  Meihin luottaa yli 22 000 asiakasta, ylläpidämme yli 50 000 verkkotunnusta ja olemme Googlen kumppani, jonka kautta yli 1 000 asiakasta on saanut käyttöönsä Google Workspace -ratkaisut sisäisenen ja ulkoiseen yhteistyöhön.
          </div> */}
        
      </div>
    </section>
   {
   /*
   // <section className="team_1 bg_white padding_top100 padding_bottom15">
     // <div className="container nopadding text-center dark_gray">
        //<h2 className="font42 font_second light">Meidän perustajat</h2>
       // <div className="max_width770 margin_auto top35 font22 light dark_blue hyphens text">
        //  Meitä yhdistää halu haastaa turhaan kuormittavia vanhoja tapoja. Haluamme ratkaista oikeita ongelmia luomalla helppokäyttöisiä työkaluja, joita kuka tahansa osaa käyttää. Asiakkaiden positiivinen palaute on paras kannustin tehdä vieläkin parempaa!
       // </div>
       // <div className="top65 flex justify_between inner">
        //  <div className="width370 margin_auto bottom70 radius10 padding_top50 padding_bottom50 block">
          //  <img src={"/images/paula.jpeg"} className="radius_full"  alt="Founder profile picture" />
           // <div className="top25 font22 light title"><a href="https://fi.linkedin.com/in/paulaviinamaki" target="blank">Paula Viinamäki</a></div>
           // <div className="top15 font12 semibold uppercase spacing10 dark_blue">Perustaja, Toimitusjohtaja</div>
         // </div>
         // <div className="width370 margin_auto bottom70 radius10 padding_top50 padding_bottom50 block">
          //  <img src="/images/jussi.jpeg" className="radius_full" alt="Co-Founder profile picture" />
           // <div className="top25 font22 light title"><a href="https://fi.linkedin.com/in/jussi-paanaj%C3%A4rvi" target="blank">Jussi Paanajärvi</a></div>
           // <div className="top15 font12 semibold uppercase spacing10 dark_blue">Perustaja, Tuotejohtaja</div>
         // </div>
        //</div>
      //</div>
    //</section>
    <Location/>
    <section className="contact_2 bg_white padding_top25 padding_bottom70">
      <div className="container nopadding max_width970 dark_blue text-center">
        <h2 className="top20 font42 font_second light">Ota yhteyttä</h2>
        <div className="max_width770 margin_auto top35 font22 light dark_blue hyphens text">
          Jos tarvitset apua aloituksen kanssa, haluat keskustella yhteistyöstä tai olet kiinnostunut tulemaan meille töihin, niin laita viestiä!
        </div>
        <div className="row top85">
          <div className="col-md-4 bottom50">
            <div className="font_second semibold font18 dark_gray title">
              <a href="https://tuki.louhi.fi/support/home" target="blank">Tuki</a>
            </div>
            <div className="max_width270 margin_auto top10 font16 light text">
              Ma - Pe, 8:00 - 16:00 <br/>
              tuki@louhi.fi<br/>
              Puhelin:  010 841 5656
            </div>
          </div>
         {
   /*
         // <div className="col-md-4 bottom50">
           // <div className="font_second semibold font18 dark_gray title">Markkinointi & Partnerit</div>
           //// <div className="max_width270 margin_auto top10 font16 light text">
            // Paula Viinamäki<br/>
            //  paula@duuers.com
           // </div>
          //</div>
         // <div className="col-md-4 bottom50">
           // <div className="font_second semibold font18 dark_gray title">
            //  <a href="https://thehub.fi/jobs/company/duuers" target="blank">Avoimet työpaikat</a>
           // </div>
           //<div className="max_width270 margin_auto top10 font16 light text">
              //Paula Viinamäki, CEO <br/>
             // paula@duuers.com
           // </div>
          //</div>
        </div>
      </div>
    </section>*/
    }
    <SignupDivider/>
    <Footer/>
  </WebLayout>
)
export default withTranslation()(AboutPage)
